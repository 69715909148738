import React, { useState } from 'react';

import withReadyTranslation from 'HOC/withReadyTranslation';

import { useNetworkStatus } from 'hooks';

import { Box, Button, Text } from 'libComponents';
import Modal from 'components/Modal';

import styles from './NoConnectionModal.module.css';

const NoConnectionModal = () => {
    const isOnline = useNetworkStatus();
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    };

    return (
        <Modal
            isOpen={!isOnline}
            withClose={false}
            isTransparency
            isPopover
            overlayClassName={styles.customOverlay}
            modalClassName={styles.customModal}
        >
            <Box paddingX={24} paddingTop={24} paddingBottom={16}>
                <span className={styles.networkIcon} role="img" aria-label="network">
                    🌐
                </span>
                <Text type="h5" center mb={12} text="networkErrorModal.title" />
                <Text
                    className={styles.subtitle}
                    center
                    color="text-secondary"
                    mb={32}
                    text="networkErrorModal.subtitle"
                />
                <Button
                    className={styles.customButton}
                    backgroundColor="surface-main"
                    isLoading={isLoading}
                    onClick={handleClick}
                    textColor="text-main"
                    hoverColor="surface-main"
                    text="common.button.title.tryAgain"
                />
            </Box>
        </Modal>
    );
};

const Component = withReadyTranslation(NoConnectionModal);

export { Component as NoConnectionModal };
