import { useTranslation } from 'react-i18next';
import React from 'react';

const withReadyTranslation = (Component: React.FC) => {
    return (props: any) => {
        const { ready } = useTranslation();

        if (!ready) {
            return null;
        }

        return <Component {...props} />;
    };
};

export default withReadyTranslation;
