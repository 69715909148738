import { ThemeProvider } from 'wikr-core-components';
import { Provider } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_ANALYTIC } from 'sentry-utils';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef } from 'react';

import config from 'config';

import { setRouteList } from 'router/helpers/helpers';
import { Routers } from 'router';

import ErrorHandler from 'api/components/ErrorHandler';

import { selectCountry, selectUserId } from 'redux/User/selectors';
import { setUserPlatform, setDefaultAmazonAnalyticData } from 'redux/User/actions';
import { selectHideHeader } from 'redux/UiEffects/selectors';
import { hidePayPal, setAppLoader } from 'redux/UiEffects/actions';
import { selectTestaniaName } from 'redux/Testania/selectors';
import { skipOnboarding } from 'redux/Onboadring/actions';

import sentry from 'services/Sentry/SentryInstance';
import { OneTrustProvider } from 'services/OneTrust';
import { initAmazonAnalytic, initHotjar } from 'services/Analytics';

import useThemeConfiguration from 'hooks/useThemeConfiguration';
import useOneTrustLoaded from 'hooks/useOneTrustLoaded/useOneTrustLoaded';

import { getDeviceOS, isFirstMirror } from 'helpers/settings';
import { getFaviconElement } from 'helpers/root/utils';
import { handleCombinedCodes } from 'helpers/combinedCodes';

import LoaderSuspense from 'pages/OtherPages/LoaderSuspense';

import { NoConnectionModal } from 'modals/NoConnectionModal';

import ReleaseConfig from 'components/ReleaseConfig/ReleaseConfig';
import NotificationWrapper from 'components/NotificationWrapper';
import NotificationModal from 'components/NotificationModal';
import Header from 'components/Header';

import themeConfig from './themeConfig.json';

import './styles.css';

const App = () => {
    const dispatch = useDispatch();

    const isHeaderHidden = useSelector(selectHideHeader);
    const { oneTrust } = useOneTrustLoaded();

    const prevCountry = useRef<string | null>();
    const prevAbTestName = useRef<string | null>();

    const country = useSelector(selectCountry);
    const abTestName = useSelector(selectTestaniaName);
    const userId = useSelector(selectUserId);

    const theme = useThemeConfiguration(themeConfig);

    const isResetStore = localStorage.getItem('isResetStore') || null;
    const isHidePayPalValue = config?.HIDE_PAYPAL || '0';
    const isHideFrontChat = !!JSON.parse(isHidePayPalValue);

    window.HIDE_PAYPAL = Number(isHidePayPalValue);

    dispatch(hidePayPal(isHideFrontChat));

    if (isResetStore) {
        dispatch({ type: 'RESET_STATE' });
    }

    useEffect(() => {
        setNewPropsToRef();
        setAnalyticData();
        setMirrorIcon();
        setUserPlatformType();

        initAmazonAnalytic();

        if (config.ENV === 'stage') {
            handleCombinedCodes(handleSkipOnboarding, ['KeyO', 'KeyB']);
            handleCombinedCodes(handleClearAllStore, ['KeyR', 'KeyC']);
        }

        const testaniaStatus = localStorage.getItem('testaniaResponseStatus');

        if (userId) {
            Provider.setUserId(Number(userId));
        }

        if (testaniaStatus) {
            dispatch(setAppLoader(false));
        }

        initHotjar();

        setRouteList();

        dispatch(setDefaultAmazonAnalyticData());
    }, []);

    useEffect(() => {
        if (prevCountry.current !== country || prevAbTestName.current !== abTestName) {
            setAnalyticData();
            setNewPropsToRef();
        }
    }, [country, abTestName]);

    const setNewPropsToRef = () => {
        prevCountry.current = country;
        prevAbTestName.current = abTestName;
    };

    const setAnalyticData = async () => {
        await Provider.provideData({
            country: async () => await getCountry(),
            abTestName: async () => await getAbTestName(),
            clientHandlerFn: (error: Error) => sentry.logError(error, SENTRY_ANALYTIC, ERROR_LEVELS.ERROR, {}),
            utmSource: getUTMSource(),
        });
    };

    const getCountry = async () => {
        return new Promise((resolve) => {
            resolve(country);
        });
    };

    const getAbTestName = () => {
        return new Promise((resolve) => {
            resolve(abTestName);
        });
    };

    const getUTMSource = () => localStorage.getItem('utmSource');

    const setMirrorIcon = () => {
        if (isFirstMirror()) {
            getFaviconElement().href = '/favicons/faviconMirror.png';
        }
    };

    const setUserPlatformType = () => {
        const { name: deviceOS } = getDeviceOS();

        dispatch(setUserPlatform(deviceOS));
    };

    const handleSkipOnboarding = () => {
        oneTrust?.allowAll();
        dispatch(
            skipOnboarding({
                reload: () => window.location.reload(),
            })
        );
    };

    const handleClearAllStore = () => {
        const isClear = window.confirm('Store has been cleared');

        if (isClear) {
            // clear local storage
            dispatch({ type: 'RESET_STATE' });
            localStorage.clear();

            setTimeout(() => {
                window.location.reload();
            }, 700);

            return;
        }
    };

    return (
        <OneTrustProvider>
            <ThemeProvider theme={theme}>
                <Router>
                    {!isHeaderHidden && <Header />}
                    <ReleaseConfig />
                    <ErrorHandler />
                    <React.Suspense fallback={<LoaderSuspense />}>
                        <Routers />
                        <NotificationWrapper />
                    </React.Suspense>
                </Router>
                <NotificationModal />
                <NoConnectionModal />
            </ThemeProvider>
        </OneTrustProvider>
    );
};

export default App;
