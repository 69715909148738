import { Main } from 'wikr-core-analytics';
import React, { useEffect } from 'react';

import withReadyTranslation from 'HOC/withReadyTranslation';

import { getProjectEmail } from 'helpers/settings';

import { Text, Button, Box } from 'libComponents';

import styles from './ErrorFallback.module.css';

const ErrorFallback = () => {
    const handleTryAgain = () => {
        Main.customData('error_popup__click', { event_label: 'something_wrong', event_action: 'try_again' });
        window.location.reload();
    };

    const handleContactSupport = () => {
        Main.customData('error_popup__click', { event_label: 'something_wrong', event_action: 'contact_support' });
        window.location.href = `mailto:${getProjectEmail()}?subject=App crash error`;
    };

    useEffect(() => {
        Main.customData('error_popup__screen__load', { event_label: 'something_wrong', event_action: 'pop_up' });
    }, []);

    return (
        <div className={styles.container}>
            <Box className={styles.box}>
                <span className={styles.icon} role="img" aria-label="crash-img">
                    😵‍💫
                </span>
                <Text text="crashApp.title" type="h5" center mb={12} />
                <Text className={styles.subtitle} text="crashApp.subtitle" center color="text-secondary" mb={32} />
                <Button
                    className={styles.buttonTryAgain}
                    onClick={handleTryAgain}
                    backgroundColor="surface-main"
                    hoverColor="surface-main"
                    textColor="text-main"
                    text="common.button.title.tryAgain"
                    mb={8}
                />
                <Button
                    className={styles.buttonContactSupport}
                    onClick={handleContactSupport}
                    size="small"
                    text="common.contactSupport"
                    backgroundColor="surface-main"
                    textColor="action-main"
                    hoverColor="surface-main"
                />
            </Box>
        </div>
    );
};

const Component = withReadyTranslation(ErrorFallback);

export { Component as ErrorFallback };
