import Core, { DataProvider } from 'testania';
import { ERROR_LEVELS, SENTRY_TESTANIA } from 'sentry-utils';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { selectUser } from 'redux/User/selectors';
import { setCountry } from 'redux/User/actions';
import { setAppLoader } from 'redux/UiEffects/actions';
import { selectTestania } from 'redux/Testania/selectors';
import { setOnboardingConfig, setTestaniaName } from 'redux/Testania/actions';
import { getPlanDynamicConfigs, initAutoLogin, setOnboardingScreensList } from 'redux/Onboadring/actions';

import { LTV_COEFFICIENT_KEY, DEFAULT_LTV_COEFFICIENT } from 'constants/payments';
import { DEFAULT_COUNTRY } from 'constants/countriesData';

import sentry from 'services/Sentry/SentryInstance';

import {
    setInitMeasure,
    setCurrentBranchToStore,
    setCompleteOBsToStore,
    clearCompleteOBsToStore,
    setTestaniaLoginStatusToStore,
    getUserStatus,
    getScreenList,
    getIsLogin,
    validateCountry,
} from './helpers/utils';

import { validateFlow } from './config/flowValidation';
import { TESTANIA_INIT_CONFIG } from './config/flowInitConfig';

function InitTestania(props) {
    const [startStatus, setStartStatus] = useState(false);
    const [currentFlowName, setCurrentFlowName] = useState(null);

    const searchParams = props.history.location.search;

    const { isAutologinComplete, country } = useSelector(selectUser);
    const { testaniaLoginStatus, completeOBs, testaniaName } = useSelector(selectTestania);

    const dispatch = useDispatch();

    const token = localStorage.getItem('token') || null;

    const toNextPage = (path) => {
        props.history.push({
            pathname: path,
            search: searchParams,
        });
    };

    const initTestania = async () => {
        localStorage.setItem(LTV_COEFFICIENT_KEY, String(DEFAULT_LTV_COEFFICIENT));

        const responseFromTestania = await Core.init(TESTANIA_INIT_CONFIG);

        if (responseFromTestania) {
            localStorage.setItem('testaniaResponseStatus', 1);

            const { country: countryFromTestania, testName: currentTestName } = responseFromTestania;

            const countryValue = validateCountry(countryFromTestania)
                ? countryFromTestania
                : localStorage.getItem('country') || DEFAULT_COUNTRY;

            dispatch(setAppLoader(false));

            currentTestName !== testaniaName && dispatch(getPlanDynamicConfigs());

            !country && dispatch(setCountry(countryValue));
            !token && setInitMeasure(countryValue, dispatch);
            currentTestName && (await dispatch(setTestaniaName(currentTestName)));
        }

        responseFromTestania?.callback();
    };

    const provideData = async () => {
        await DataProvider.provideInitialData({
            loginStatus: await getIsLogin(testaniaLoginStatus, token),
            startStatus,
            startAction: async () => setStartStatus(true),
            toNextPage,
            pathTestaniaPaidFinish: 'payment-approved',
            pathTestaniaRegistrationFinish: 'fitcoach-app',
            // TODO: need to add param is_password_set from BE
            getUserStatus: () => getUserStatus(dispatch, token),
            getToken: async () => token,
            getCompleteOBs: async () => completeOBs,
            getCurrentBranch: async () => testaniaName,
            setCurrentBranch: async (branch) => setCurrentBranchToStore(branch, dispatch),
            setCompleteOBs: async (branch) => setCompleteOBsToStore(branch, dispatch),
            clearCompleteOBs: () => clearCompleteOBsToStore(dispatch),
            setLoginStatus: async (status) => setTestaniaLoginStatusToStore(status, dispatch),
            customFilter: (config, { country }) =>
                validateFlow({ config, dispatch, country, currentFlowName, setCurrentFlowName }),
            errorHandler: (error, errorLevel) =>
                sentry.logError(error, SENTRY_TESTANIA, errorLevel || ERROR_LEVELS.ERROR),
        });
    };

    const initTestaniaDependencies = async () => {
        await provideData();
        await initTestania();

        const onboardingFlow = DataProvider.getOnboardingFlow();

        const screensList = getScreenList(onboardingFlow);

        dispatch(setOnboardingScreensList(screensList));
        dispatch(setOnboardingConfig(onboardingFlow));
    };

    useEffect(() => {
        provideData();
    }, [token, completeOBs, testaniaName]);

    useEffect(() => {
        dispatch(initAutoLogin());
    }, []);

    useEffect(() => {
        if (isAutologinComplete) {
            try {
                initTestaniaDependencies();
            } catch (e) {
                console.error(
                    `Error with init testania, check initial methods in - initTestaniaDependencies \n \n ${e}`
                );
            }
        }
    }, [isAutologinComplete]);

    return null;
}

export default withRouter(InitTestania);
